'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./search/search'));
    processInclude(require('./components/priceSlider'));
    processInclude(require('./product/compare'));
    processInclude(require('./components/attributeSlider'));
});
